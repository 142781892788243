import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { ThemeContext } from '../ThemeContext';

const SelectionDetailsPage = () => {
  const { theme } = useContext(ThemeContext);

  const selection = {
    name: "AI Innovations",
    expert: {
      name: "Dr. Emily Chen",
      id: 1,
    },
    description: "A curated selection of cutting-edge AI startups poised to revolutionize various industries.",
    startups: [
      { id: 1, name: "NeuralTech", description: "Advanced neural network solutions for complex data analysis." },
      { id: 2, name: "AIAssist", description: "AI-powered virtual assistants for improved productivity." },
      { id: 3, name: "DeepLearn", description: "Deep learning platforms for automated decision-making systems." },
    ],
    fundingGoal: 1000000,
    currentFunding: 750000,
    backers: 120,
    remainingDays: 15,
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-4" style={{ color: theme.textColor }}>{selection.name}</h1>
      <p className="mb-4" style={{ color: theme.textColor }}>Led by <Link to={`/expert/${selection.expert.id}`} className="font-semibold" style={{ color: theme.primaryColor }}>{selection.expert.name}</Link></p>
      <p className="mb-8" style={{ color: theme.textColor }}>{selection.description}</p>

      <div className="mb-8 p-4 rounded-lg" style={{ backgroundColor: theme.cardBgColor }}>
        <h2 className="text-2xl font-semibold mb-4" style={{ color: theme.textColor }}>Funding Progress</h2>
        <div className="mb-2 flex justify-between">
          <span style={{ color: theme.textColor }}>${selection.currentFunding.toLocaleString()} raised</span>
          <span style={{ color: theme.textColor }}>${selection.fundingGoal.toLocaleString()} goal</span>
        </div>
        <div className="w-full bg-gray-200 rounded-full h-2.5 mb-4">
          <div className="h-2.5 rounded-full" style={{ width: `${(selection.currentFunding / selection.fundingGoal) * 100}%`, backgroundColor: theme.primaryColor }}></div>
        </div>
        <div className="flex justify-between text-sm">
          <span style={{ color: theme.textColor }}>{selection.backers} backers</span>
          <span style={{ color: theme.textColor }}>{selection.remainingDays} days to go</span>
        </div>
      </div>

      <h2 className="text-2xl font-semibold mb-4" style={{ color: theme.textColor }}>Featured Startups</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-8">
        {selection.startups.map(startup => (
          <div key={startup.id} className="p-4 rounded-lg" style={{ backgroundColor: theme.cardBgColor }}>
            <h3 className="text-xl font-semibold mb-2" style={{ color: theme.textColor }}>{startup.name}</h3>
            <p className="mb-4" style={{ color: theme.textColor }}>{startup.description}</p>
            <Link to={`/startup/${startup.id}`} className="text-sm font-semibold" style={{ color: theme.primaryColor }}>Learn More</Link>
          </div>
        ))}
      </div>

      <Link
        to="/contribute"
        className="inline-block px-6 py-3 rounded-full text-white text-lg font-semibold"
        style={{ backgroundColor: theme.primaryColor }}
      >
        Contribute to This Selection
      </Link>
    </div>
  );
};

export default SelectionDetailsPage;