import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { ThemeContext } from '../ThemeContext';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../AuthContext';
import UserAuthModel from '../models/userAuth';

const Auth = () => {
  const [isLogin, setIsLogin] = useState(true);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { theme } = useContext(ThemeContext);
  const { t } = useTranslation();
  const { user } = useAuth();

  useEffect(() => {
    if (user) {
      navigate('/dashboard');
    }
  }, [user, navigate]);

  const getErrorMessage = (errorCode) => {
    switch (errorCode) {
      case 'auth/email-already-in-use':
        return t('auth.errors.emailInUse');
      case 'auth/wrong-password':
        return t('auth.errors.wrongPassword');
      case 'auth/user-not-found':
        return t('auth.errors.userNotFound');
      case 'auth/invalid-email':
        return t('auth.errors.invalidEmail');
      case 'auth/weak-password':
        return t('auth.errors.weakPassword');
      default:
        return t('auth.errors.unknown');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    try {
      const userAuth = new UserAuthModel();
      if (isLogin) {
        await userAuth.signIn(email, password);
      } else {
        await userAuth.signUp(email, password, email.split('@')[0]);
      }
      navigate('/dashboard');
    } catch (error) {
      console.error('Error in handleSubmit:', error);
      setError(getErrorMessage(error.code));
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      const userAuth = new UserAuthModel();
      await userAuth.signInWithGoogle();
      navigate('/dashboard');
    } catch (error) {
      console.error('Error in handleGoogleSignIn:', error);
      setError(getErrorMessage(error.code));
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen py-12 px-4 sm:px-6 lg:px-8" style={{ color: theme.textColor }}>
      <div className="max-w-md w-full space-y-6">
        <h2 className="mt-6 text-center text-3xl font-extrabold">
          {isLogin ? t('auth.welcomeBack') : t('auth.createAccount')}
        </h2>
        {error && (
          <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
            <span className="block sm:inline">{error}</span>
          </div>
        )}
        <form onSubmit={handleSubmit} className="mt-8 space-y-6">
          <div className="rounded-md shadow-sm -space-y-px">
            <div>
              <label htmlFor="email" className="sr-only">
                {t('auth.email')}
              </label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-600 placeholder-gray-400 text-white rounded-t-md focus:outline-none focus:ring-primary focus:border-primary focus:z-10 sm:text-sm bg-gray-800"
                placeholder={t('auth.email')}
              />
            </div>
            <div>
              <label htmlFor="password" className="sr-only">
                {t('auth.password')}
              </label>
              <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-600 placeholder-gray-400 text-white rounded-b-md focus:outline-none focus:ring-primary focus:border-primary focus:z-10 sm:text-sm bg-gray-800"
                placeholder={t('auth.password')}
              />
            </div>
          </div>

          <div>
            <button
              type="submit"
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
              style={{ backgroundColor: theme.primaryColor, borderColor: theme.primaryColor }}
            >
              {t('auth.continue')}
            </button>
          </div>
        </form>

        <p className="text-center text-sm" style={{ color: theme.textColor }}>
          {isLogin ? t('auth.noAccount') : t('auth.haveAccount')}{' '}
          <button
            onClick={() => setIsLogin(!isLogin)}
            className="font-bold text-primary hover:text-primary-dark"
          >
            {isLogin ? t('auth.signUp') : t('auth.signIn')}
          </button>
        </p>

        <div className="relative flex items-center">
          <div className="flex-grow border-t border-gray-600"></div>
          <span className="flex-shrink mx-3 text-gray-500">{t('auth.or')}</span>
          <div className="flex-grow border-t border-gray-600"></div>
        </div>

        <div>
          <button
            onClick={handleGoogleSignIn}
            className="w-full flex items-center justify-center px-4 py-2 border border-gray-600 rounded-md shadow-sm text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
            style={{ backgroundColor: theme.cardBgColor }}
          >
            <svg className="w-5 h-5 mr-2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z" fill="#4285F4" />
              <path d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z" fill="#34A853" />
              <path d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z" fill="#FBBC05" />
              <path d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z" fill="#EA4335" />
            </svg>
            {t('auth.continueWithGoogle')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Auth;