import React from 'react';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { ThemeContext } from '../ThemeContext';

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const { theme } = useContext(ThemeContext);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <div className="flex space-x-1">
      <button
        className={`px-1 py-1 ${
          i18n.language === 'en-CA' ? '' : 'text-gray-500 hover:text-text'
        }`}
        onClick={() => changeLanguage('en-CA')}
        style={{ color: i18n.language === 'en-CA' ? theme.textColor : '' }}
      >
        EN
      </button>
      <button
        className={`px-1 py-1 ${
          i18n.language === 'fr-CA' ? '' : 'text-gray-500 hover:text-text'
        }`}
        onClick={() => changeLanguage('fr-CA')}
        style={{ color: i18n.language === 'fr-CA' ? theme.textColor : '' }}
      >
        FR
      </button>
    </div>
  );
};

export default LanguageSwitcher;