import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { ThemeContext } from '../ThemeContext';

const ExpertCard = ({ expert }) => {
  const { theme } = useContext(ThemeContext);
  
  return (
    <div className="bg-white rounded-lg shadow-md overflow-hidden" style={{ backgroundColor: theme.cardBgColor }}>
      <img src={expert.image} alt={expert.name} className="w-full h-48 object-cover" />
      <div className="p-4">
        <h3 className="text-xl font-semibold mb-2" style={{ color: theme.textColor }}>{expert.name}</h3>
        <p className="text-sm mb-4" style={{ color: theme.textColor }}>{expert.specialty}</p>
        <Link
          to={`/expert/${expert.id}`}
          className="inline-block px-4 py-2 rounded-full text-white text-sm font-semibold"
          style={{ backgroundColor: theme.primaryColor }}
        >
          View Profile
        </Link>
      </div>
    </div>
  );
};

const ExpertProfilesPage = () => {
  const { theme } = useContext(ThemeContext);
  
  const experts = [
    { id: 1, name: "Dr. Emily Chen", specialty: "AI and Machine Learning", image: "https://randomuser.me/api/portraits/women/1.jpg" },
    { id: 2, name: "John Smith", specialty: "Blockchain and Cryptocurrencies", image: "https://randomuser.me/api/portraits/men/1.jpg" },
    { id: 3, name: "Sarah Johnson", specialty: "Biotech and Healthcare", image: "https://randomuser.me/api/portraits/women/2.jpg" },
    { id: 4, name: "Michael Brown", specialty: "Clean Energy and Sustainability", image: "https://randomuser.me/api/portraits/men/2.jpg" },
  ];

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-8" style={{ color: theme.textColor }}>Expert Profiles</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {experts.map(expert => (
          <ExpertCard key={expert.id} expert={expert} />
        ))}
      </div>
    </div>
  );
};

export default ExpertProfilesPage;