import React, { useContext, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n'; // Import the i18n configuration
import Navigation from './pages/Navigation';
import LandingPage from './pages/LandingPage';
import AuthPage from './pages/AuthPage';
import Dashboard from './pages/Dashboard';
import ExplorePage from './pages/ExplorePage';
import InvestmentsPage from './pages/InvestmentsPage';
import ProfilePage from './pages/ProfilePage';
import SelectionDetailsPage from './pages/SelectionDetailsPage';
import StartupProfilePage from './pages/StartupProfilePage';
import ContributionPage from './pages/ContributionPage';
import PaymentProcessingPage from './pages/PaymentProcessingPage';
import ConfirmationPage from './pages/ConfirmationPage';
import ExpertProfilesPage from './pages/ExpertProfilesPage';
import AddLeadInvestorPage from './pages/AddLeadInvestorPage';
import ColorCustomizer from './components/ColorCustomizer';
import ProtectedRoute from './components/ProtectedRoute';
import { FiSettings } from 'react-icons/fi';
import { ThemeContext } from './ThemeContext';
import { AuthProvider } from './AuthContext';

function App() {
  const { theme } = useContext(ThemeContext);
  const [showColorPicker, setShowColorPicker] = React.useState(false);

  const toggleColorPicker = () => {
    setShowColorPicker(!showColorPicker);
  };

  useEffect(() => {
    document.body.style.backgroundColor = theme.appBackgroundColor;
  }, [theme.appBackgroundColor]);

  return (
    <I18nextProvider i18n={i18n}>
      <AuthProvider>
        <div className="min-h-screen" style={{ color: theme.textColor }}>
          <Navigation />
          <div className="pt-16 pb-16 md:pb-0">
            <Routes>
              <Route path="/" element={<LandingPage />} />
              <Route path="/auth" element={<AuthPage />} />
              <Route path="/explore" element={<ExplorePage />} />
              <Route path="/selection/:id" element={<SelectionDetailsPage />} />
              <Route path="/startup/:id" element={<StartupProfilePage />} />
              <Route path="/experts" element={<ExpertProfilesPage />} />
              
              {/* Protected Routes */}
              <Route path="/dashboard" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
              <Route path="/investments" element={<ProtectedRoute><InvestmentsPage /></ProtectedRoute>} />
              <Route path="/profile" element={<ProtectedRoute><ProfilePage /></ProtectedRoute>} />
              <Route path="/contribute/:id" element={<ProtectedRoute><ContributionPage /></ProtectedRoute>} />
              <Route path="/payment-processing" element={<ProtectedRoute><PaymentProcessingPage /></ProtectedRoute>} />
              <Route path="/confirmation" element={<ProtectedRoute><ConfirmationPage /></ProtectedRoute>} />
              <Route path="/add-lead-investor" element={<ProtectedRoute><AddLeadInvestorPage /></ProtectedRoute>} />
            </Routes>
          </div>

          {/* Floating Color Picker Button */}
          <div className="fixed bottom-4 right-4 z-50">
            <button
              onClick={toggleColorPicker}
              className="bg-primary rounded-full p-3 shadow-lg text-white"
              style={{ backgroundColor: theme.primaryColor }}
            >
              <FiSettings className="w-6 h-6" />
            </button>
          </div>

          {/* Color Picker Dropdown */}
          {showColorPicker && (
            <div className="fixed bottom-16 right-4 bg-gray-800 p-4 rounded-lg shadow-lg z-50">
              <ColorCustomizer />
            </div>
          )}
        </div>
      </AuthProvider>
    </I18nextProvider>
  );
}

export default App;
