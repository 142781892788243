import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Users, TrendingUp, Shield, Search } from 'lucide-react';
import { ThemeContext } from '../ThemeContext';
import { useTranslation } from 'react-i18next';
import Navigation from './Navigation';

const Button = ({ children, primary, to }) => {
  const { theme } = useContext(ThemeContext);
  return (
    <Link
      to={to}
      className={`px-6 py-2 rounded-full font-bold ${primary ? 'bg-primary text-white' : 'bg-white text-primary'
        }`}
      style={{ backgroundColor: primary ? theme.primaryColor : theme.textColor, color: primary ? theme.textColor : theme.primaryColor }}
    >
      {children}
    </Link>
  );
};

const FeatureCard = ({ icon: Icon, title, description }) => {
  const { theme } = useContext(ThemeContext);
  return (
    <div className="bg-gray-800 p-6 rounded-lg">
      <Icon className="w-12 h-12 mb-4" style={{ color: theme.secondaryColor }} />
      <h3 className="text-xl font-bold mb-2" style={{ color: theme.textColor }}>{title}</h3>
      <p style={{ color: theme.textColor }}>{description}</p>
    </div>
  );
};

const LandingPage = () => {
  const { theme } = useContext(ThemeContext);
  const { t } = useTranslation();

  return (
    <div className="min-h-screen">
      <Navigation />

      <main className="container mx-auto px-4 pt-24">
        <section className="text-center py-20">
          <h2 className="text-4xl font-bold mb-4" style={{ color: theme.textColor }}>
            {t('landingPage.headline')}
          </h2>
          <p className="text-xl mb-8" style={{ color: theme.textColor }}>
            {t('landingPage.subheadline')}
          </p>
          <Button primary to="/auth">{t('getStarted')}</Button>
        </section>

        <section className="py-20">
          <h2 className="text-3xl font-bold mb-12 text-center" style={{ color: theme.textColor }}>
            {t('landingPage.whyChooseSteerUp')}
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            <FeatureCard
              icon={Users}
              title={t('landingPage.features.expertLed.title')}
              description={t('landingPage.features.expertLed.description')}
            />
            <FeatureCard
              icon={TrendingUp}
              title={t('landingPage.features.growthPotential.title')}
              description={t('landingPage.features.growthPotential.description')}
            />
            <FeatureCard
              icon={Shield}
              title={t('landingPage.features.securePlatform.title')}
              description={t('landingPage.features.securePlatform.description')}
            />
            <FeatureCard
              icon={Search}
              title={t('landingPage.features.diverseOpportunities.title')}
              description={t('landingPage.features.diverseOpportunities.description')}
            />
          </div>
        </section>

        <section className="text-center py-20">
          <h2 className="text-3xl font-bold mb-4" style={{ color: theme.textColor }}>
            {t('landingPage.cta.title')}
          </h2>
          <p className="text-xl mb-8" style={{ color: theme.textColor }}>
            {t('landingPage.cta.description')}
          </p>
          <Button primary to="/auth">{t('createAccount')}</Button>
        </section>
      </main>

      <footer className="container mx-auto px-4 py-6 text-center" style={{ color: theme.textColor }}>
        {t('footer.copyright', { year: new Date().getFullYear() })}
      </footer>
    </div>
  );
};

export default LandingPage;