import { doc, getDoc, setDoc, updateDoc, serverTimestamp } from 'firebase/firestore';
import db from '../config/firestore';

const SCHEMA_VERSION = process.env.REACT_APP_DB_SCHEMA_VERSION || 'v0';

class UserModel {
  constructor(uid = null, userData = {}) {
    const defaultData = {
      uid: uid,
      email: '',
      displayName: '',
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
      roles: {
        startup_investor: true,
        startup_selector: false,
        startup_admin: false,
        steerup_platform_admin: false
      },
      kycStatus: 'notStarted',
      schemaVersion: SCHEMA_VERSION,
      socialLogins: {}
    };

    this.data = { ...defaultData, ...userData };
    if (uid) {
      this.setUid(uid);
    }
  }

  setUid(uid) {
    this.data.uid = uid;
    this.userRef = doc(db, 'users', uid);
  }

  async create() {
    if (!this.data.uid) {
      throw new Error('Cannot create user without uid');
    }
    await setDoc(this.userRef, this.data);
    return this.get();
  }

  async get() {
    if (!this.userRef) {
      throw new Error('Cannot get user without uid');
    }
    const docSnap = await getDoc(this.userRef);
    if (docSnap.exists()) {
      let foundUser = docSnap.data();
      this.data = foundUser;
      return foundUser;
    } else {
      throw new Error('User not found');
    }
  }

  async update(updateData) {
    if (!this.userRef) {
      throw new Error('Cannot update user without uid');
    }
    const updatedData = { ...updateData, updatedAt: serverTimestamp() };
    await updateDoc(this.userRef, updatedData);
    this.data = await this.get();
    return this.data;
  }

  async addSocialLogin(provider, providerData) {
    const socialLoginData = {
      providerId: providerData.providerId,
      uid: providerData.uid,
      displayName: providerData.displayName,
      email: providerData.email,
      photoURL: providerData.photoURL
    };

    const updatedSocialLogins = {
      ...this.data.socialLogins,
      [provider]: socialLoginData
    };

    return this.update({ socialLogins: updatedSocialLogins });
  }
}

export default UserModel;
