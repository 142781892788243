import React, { useContext, useState } from 'react';
import { ThemeContext } from '../ThemeContext';
import { useTranslation } from 'react-i18next';

const ColorCustomizer = () => {
    const { theme, updateTheme } = useContext(ThemeContext);
    const [newTheme, setNewTheme] = useState(theme);
    const { t } = useTranslation();

    const handleColorChange = (colorType, colorValue) => {
        const updatedTheme = {
            ...newTheme,
            [colorType]: colorValue,
        };
        setNewTheme(updatedTheme);
        updateTheme(updatedTheme);  // Update the theme in the context immediately
    };

    return (
        <div className="space-y-2">
            <div className="flex items-center justify-between">
                <label className="text-sm font-medium">{t('colorCustomizer.primaryColor')}</label>
                <input
                    type="color"
                    value={newTheme.primaryColor}
                    onChange={(e) => handleColorChange('primaryColor', e.target.value)}
                    className="w-10 h-10 p-0 border-none cursor-pointer bg-transparent"
                />
            </div>
            <div className="flex items-center justify-between">
                <label className="text-sm font-medium">{t('colorCustomizer.secondaryColor')}</label>
                <input
                    type="color"
                    value={newTheme.secondaryColor}
                    onChange={(e) => handleColorChange('secondaryColor', e.target.value)}
                    className="w-10 h-10 p-0 border-none cursor-pointer bg-transparent"
                />
            </div>
            <div className="flex items-center justify-between">
                <label className="text-sm font-medium">{t('colorCustomizer.fundingBgColor')}</label>
                <input
                    type="color"
                    value={newTheme.additionalFundingBgColor}
                    onChange={(e) => handleColorChange('additionalFundingBgColor', e.target.value)}
                    className="w-10 h-10 p-0 border-none cursor-pointer bg-transparent"
                />
            </div>
            <div className="flex items-center justify-between">
                <label className="text-sm font-medium">{t('colorCustomizer.cardBgColor')}</label>
                <input
                    type="color"
                    value={newTheme.cardBgColor}
                    onChange={(e) => handleColorChange('cardBgColor', e.target.value)}
                    className="w-10 h-10 p-0 border-none cursor-pointer bg-transparent"
                />
            </div>
            <div className="flex items-center justify-between">
                <label className="text-sm font-medium">{t('colorCustomizer.textColor')}</label>
                <input
                    type="color"
                    value={newTheme.textColor}
                    onChange={(e) => handleColorChange('textColor', e.target.value)}
                    className="w-10 h-10 p-0 border-none cursor-pointer bg-transparent"
                />
            </div>
            <div className="flex items-center justify-between">
                <label className="text-sm font-medium">{t('colorCustomizer.expertBadgeColor')}</label>
                <input
                    type="color"
                    value={newTheme.expertBadgeColor}
                    onChange={(e) => handleColorChange('expertBadgeColor', e.target.value)}
                    className="w-10 h-10 p-0 border-none cursor-pointer bg-transparent"
                />
            </div>
            <div className="flex items-center justify-between">
                <label className="text-sm font-medium">{t('colorCustomizer.appBackgroundColor')}</label>
                <input
                    type="color"
                    value={newTheme.appBackgroundColor}
                    onChange={(e) => handleColorChange('appBackgroundColor', e.target.value)}
                    className="w-10 h-10 p-0 border-none cursor-pointer bg-transparent"
                />
            </div>
        </div>
    );
};

export default ColorCustomizer;
