import React, { useContext } from 'react';
import Auth from '../components/Auth';
import { ThemeContext } from '../ThemeContext';

const AuthPage = () => {
  const { theme } = useContext(ThemeContext);

  return (
    <div className="min-h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8" style={{ backgroundColor: theme.appBackgroundColor }}>
      <div className="max-w-md w-full space-y-8">
        <Auth />
      </div>
    </div>
  );
};

export default AuthPage;