import React, { useState, useContext, useEffect } from 'react';
import { NavLink, Link, useNavigate } from 'react-router-dom';
import { Home, Search, PieChart, User, Menu, X, LogIn, LogOut, UserPlus } from 'lucide-react';
import { ThemeContext } from '../ThemeContext';
import SteerUpLogo from '../components/SteerUpLogo';
import { auth } from '../config/firebase';
import { signOut } from 'firebase/auth';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from '../components/LanguageSwitcher';

const NavItem = ({ icon: Icon, label, to, onClick }) => {
  const { theme } = useContext(ThemeContext);
  return (
    <NavLink
      to={to}
      className={({ isActive }) =>
        `flex flex-col items-center p-2 ${isActive ? 'text-secondary' : 'text-textLight'}`
      }
      style={({ isActive }) => ({
        color: isActive ? theme.secondaryColor : theme.textColor,
      })}
      onClick={onClick}
    >
      <Icon className="w-6 h-6 mb-1" />
      <span className="text-xs">{label}</span>
    </NavLink>
  );
};

const Navigation = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { theme } = useContext(ThemeContext);
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
    });
    return () => unsubscribe();
  }, []);

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      navigate('/');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const navItems = [
    { icon: Home, label: t('dashboard'), to: '/dashboard', requiresAuth: true },
    { icon: Search, label: t('explore'), to: '/explore', requiresAuth: false },
    { icon: PieChart, label: t('investments'), to: '/investments', requiresAuth: true },
    { icon: User, label: t('profile'), to: '/profile', requiresAuth: true },
    { icon: UserPlus, label: t('addLeadInvestor'), to: '/add-lead-investor', requiresAuth: true },
  ];

  const filteredNavItems = user
    ? navItems
    : navItems.filter(item => !item.requiresAuth);

  const authItem = user
    ? { icon: LogOut, label: t('logout'), onClick: handleSignOut }
    : { icon: LogIn, label: t('login'), to: '/auth' };

  return (
    <>
      {/* Mobile Top Logo */}
      <div className="md:hidden fixed top-0 left-0 right-0 p-4 z-40" style={{ backgroundColor: theme.appBackgroundColor }}>
        <Link to="/" className="w-32 inline-block">
          <SteerUpLogo color={theme.primaryColor} />
        </Link>
      </div>

      {/* Mobile Bottom Navigation */}
      <nav className="md:hidden fixed bottom-0 left-0 right-0" style={{ backgroundColor: theme.appBackgroundColor }}>
        <div className="flex justify-around">
          {filteredNavItems.map((item) => (
            <NavItem
              key={item.label}
              {...item}
            />
          ))}
          <NavItem {...authItem} />
        </div>
      </nav>

      {/* Desktop Navigation */}
      <nav className="hidden md:flex fixed top-0 left-0 right-0 p-4" style={{ backgroundColor: theme.appBackgroundColor }}>
        <div className="container mx-auto flex justify-between items-center">
          <Link to="/" className="w-40 -ml-4">
            <SteerUpLogo color={theme.primaryColor} />
          </Link>
          <div className="flex space-x-4 items-center">
            {filteredNavItems.map((item) => (
              <NavLink
                key={item.label}
                to={item.to}
                className={({ isActive }) =>
                  `flex items-center px-3 py-2 rounded-full ${isActive ? 'bg-primary' : ''}`
                }
                style={({ isActive }) => ({
                  backgroundColor: isActive ? theme.primaryColor : 'transparent',
                  color: theme.textColor,
                })}
              >
                <item.icon className="w-5 h-5 mr-2" />
                {item.label}
              </NavLink>
            ))}
            <NavLink
              to={authItem.to}
              onClick={authItem.onClick}
              className="flex items-center px-3 py-2 rounded-full"
              style={{ color: theme.textColor }}
            >
              <authItem.icon className="w-5 h-5 mr-2" />
              {authItem.label}
            </NavLink>
            <LanguageSwitcher />
          </div>
        </div>
      </nav>

      {/* Mobile Menu Button */}
      <button
        className="md:hidden fixed top-4 right-4 z-50"
        onClick={() => setIsOpen(!isOpen)}
      >
        {isOpen ? <X style={{ color: theme.textColor }} /> : <Menu style={{ color: theme.textColor }} />}
      </button>

      {/* Mobile Slide-out Menu */}
      {isOpen && (
        <div className="md:hidden fixed inset-0 z-40 flex flex-col p-4" style={{ backgroundColor: theme.appBackgroundColor }}>
          <div className="w-40 mb-8 -ml-4">
            <SteerUpLogo color={theme.primaryColor} />
          </div>
          {filteredNavItems.map((item) => (
            <NavLink
              key={item.label}
              to={item.to}
              className="flex items-center p-4 text-left"
              style={{ color: theme.textColor }}
              onClick={() => setIsOpen(false)}
            >
              <item.icon className="w-6 h-6 mr-4" />
              {item.label}
            </NavLink>
          ))}
          <NavLink
            to={authItem.to}
            onClick={() => {
              setIsOpen(false);
              if (authItem.onClick) authItem.onClick();
            }}
            className="flex items-center p-4 text-left"
            style={{ color: theme.textColor }}
          >
            <authItem.icon className="w-6 h-6 mr-4" />
            {authItem.label}
          </NavLink>
          <div className="mt-4">
            <LanguageSwitcher />
          </div>
        </div>
      )}
    </>
  );
};

export default Navigation;
