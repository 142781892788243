import { initializeApp } from 'firebase/app';
import { getAuth, connectAuthEmulator } from 'firebase/auth';

let auth;
let app;

const productionConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

const developmentConfig = {
  apiKey: 'fake-api-key',
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_EMULATOR_HOST,
};

// Check if either the Auth or Functions Emulator is being used
const isUsingAuthEmulator = !!process.env.REACT_APP_FIREBASE_AUTH_EMULATOR_HOST;
const isUsingFunctionsEmulator = !!process.env.REACT_APP_FIREBASE_FUNCTIONS_EMULATOR_HOST;
const isUsingEmulators = isUsingAuthEmulator || isUsingFunctionsEmulator;

console.log('Environment Variables:');
console.log('REACT_APP_FIREBASE_AUTH_EMULATOR_HOST:', process.env.REACT_APP_FIREBASE_AUTH_EMULATOR_HOST);
console.log('REACT_APP_FIREBASE_FUNCTIONS_EMULATOR_HOST:', process.env.REACT_APP_FIREBASE_FUNCTIONS_EMULATOR_HOST);

console.log('Auth Emulator:', isUsingAuthEmulator ? 'Enabled' : 'Disabled');
console.log('Functions Emulator:', isUsingFunctionsEmulator ? 'Enabled' : 'Disabled');

// Use development config if either emulator is being used, otherwise use production config
const firebaseConfig = isUsingEmulators ? developmentConfig : productionConfig;

console.log('Using config:', isUsingEmulators ? 'Development' : 'Production');

// Initialize Firebase app
app = initializeApp(firebaseConfig);
auth = getAuth(app);

// Connect to Firebase Auth Emulator if REACT_APP_FIREBASE_AUTH_EMULATOR_HOST is defined
if (isUsingAuthEmulator) {
  connectAuthEmulator(auth, `http://${process.env.REACT_APP_FIREBASE_AUTH_EMULATOR_HOST}`);
  console.log('Connected to Auth Emulator');
}

export { auth, app };