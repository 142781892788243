import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';
import { app } from './firebase';

const db = getFirestore(app);

if (process.env.REACT_APP_FIRESTORE_EMULATOR_HOST) {
  const [host, port] = process.env.REACT_APP_FIRESTORE_EMULATOR_HOST.split(':');
  connectFirestoreEmulator(db, host, parseInt(port, 10));
}

export default db;