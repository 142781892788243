import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { ThemeContext } from '../ThemeContext';
import { useTranslation } from 'react-i18next';

const ConfirmationPage = () => {
  const { theme } = useContext(ThemeContext);
  const { t } = useTranslation();

  // Mock data for the confirmation
  const confirmationData = {
    selectionName: "AI Innovations",
    amount: 5000,
    date: new Date().toLocaleDateString(),
    transactionId: "TRX123456789",
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="max-w-md mx-auto">
        <div className="text-center mb-8">
          <svg className="inline-block h-16 w-16 text-green-500 mb-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
          </svg>
          <h1 className="text-3xl font-bold mb-2" style={{ color: theme.textColor }}>{t('confirmationPage.paymentSuccessful')}</h1>
          <p style={{ color: theme.textColor }}>{t('confirmationPage.processedSuccessfully')}</p>
        </div>

        <div className="bg-white rounded-lg shadow-md p-6 mb-8" style={{ backgroundColor: theme.cardBgColor }}>
          <h2 className="text-xl font-semibold mb-4" style={{ color: theme.textColor }}>{t('confirmationPage.confirmationDetails')}</h2>
          <div className="space-y-2">
            <p style={{ color: theme.textColor }}><span className="font-medium">{t('confirmationPage.selection')}:</span> {confirmationData.selectionName}</p>
            <p style={{ color: theme.textColor }}><span className="font-medium">{t('confirmationPage.amount')}:</span> ${confirmationData.amount}</p>
            <p style={{ color: theme.textColor }}><span className="font-medium">{t('confirmationPage.date')}:</span> {confirmationData.date}</p>
            <p style={{ color: theme.textColor }}><span className="font-medium">{t('confirmationPage.transactionId')}:</span> {confirmationData.transactionId}</p>
          </div>
        </div>

        <div className="text-center space-y-4">
          <Link
            to="/dashboard"
            className="block w-full py-2 px-4 rounded-full font-bold text-white"
            style={{ backgroundColor: theme.primaryColor }}
          >
            {t('confirmationPage.goToDashboard')}
          </Link>
          <Link
            to="/investments"
            className="block w-full py-2 px-4 rounded-full font-bold"
            style={{ backgroundColor: 'transparent', borderWidth: 1, borderStyle: 'solid', borderColor: theme.primaryColor, color: theme.primaryColor }}
          >
            {t('confirmationPage.viewMyInvestments')}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationPage;