import React, { useContext } from 'react';
import { ThemeContext } from '../../ThemeContext';

const Card = React.forwardRef(({ className, ...props }, ref) => {
    const { theme } = useContext(ThemeContext);

    return (
        <div
            ref={ref}
            className={`rounded-lg border shadow-sm ${className}`}
            style={{ backgroundColor: theme.cardBgColor, color: theme.textColor }}
            {...props}
        />
    );
});
Card.displayName = "Card";

const CardHeader = React.forwardRef(({ className, ...props }, ref) => (
    <div
        ref={ref}
        className={`flex flex-col space-y-1.5 p-6 ${className}`}
        {...props}
    />
));
CardHeader.displayName = "CardHeader";

const CardTitle = React.forwardRef(({ className, children, ...props }, ref) => (
    <h3
        ref={ref}
        className={`text-2xl font-semibold leading-none tracking-tight ${className}`}
        {...props}
    >
        {children || 'Card Title'}
    </h3>
));
CardTitle.displayName = "CardTitle";

const CardContent = React.forwardRef(({ className, ...props }, ref) => (
    <div ref={ref} className={`p-6 pt-0 ${className}`} {...props} />
));
CardContent.displayName = "CardContent";

export { Card, CardHeader, CardTitle, CardContent };
