import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Search } from 'lucide-react';
import StartupsSelectionCard from '../components/StartupsSelectionCard';
import { ThemeContext } from '../ThemeContext';
import { useTranslation } from 'react-i18next';

const ExplorePage = () => {
  const { theme } = useContext(ThemeContext);
  const [searchTerm, setSearchTerm] = useState('');
  const [activeFilter, setActiveFilter] = useState('All');
  const { t } = useTranslation();

  const filters = ['All', 'Tech', 'Health', 'Finance', 'Green'];

  // Mock data for startup selections
  const selections = [
    {
      id: 'immersive-gaming',
      name: "Immersive Gaming Experience",
      expert: {
        name: "Emily Chen",
        imageUrl: "emily-chen.webp",
        credentials: "Neuroscientist, Former CTO of BrainTech Innovations"
      },
      startups: [
        { name: "VRcade", description: "Creating next-gen VR arcade experiences for multiplayer gaming.", imageUrl: "/images/startups/vrcade.png" },
        { name: "EcoQuest", description: "Developing educational games focused on environmental awareness.", imageUrl: "/images/startups/ecoquest.png" },
        { name: "NeuroPlay", description: "Brain-computer interface games for improved cognitive function.", imageUrl: "/images/startups/neuroplay.png" }
      ],
      fundingGoal: 100000,
      currentFunding: 20000,
      backers: 1025,
      remainingDays: 31,
      additionalFunding: [
        { source: { name: "Ministère de l'Économie et de l'Innovation" }, type: "Government Grant", amount: 1000000 },
        { source: { name: "McGill" }, type: "Public University Grant", amount: 150000 }
      ]
    },
    {
      id: 'ai-health',
      name: "AI-Driven HealthTech",
      expert: {
        name: "Dr. Michael Smith",
        imageUrl: "michael-smith.webp",
        credentials: "HealthTech Innovator, Professor of Medical Technology"
      },
      startups: [
        { name: "HealthAI", description: "AI-powered diagnostics for early disease detection.", imageUrl: "/images/startups/healthai.png" },
        { name: "NutriScan", description: "Personalized nutrition plans using AI and genetic data.", imageUrl: "/images/startups/nutriscan.png" }
      ],
      fundingGoal: 150000,
      currentFunding: 75000,
      backers: 850,
      remainingDays: 45,
      additionalFunding: [
        { source: { name: "Health Canada" }, type: "Government Grant", amount: 750000 }
      ]
    }
  ];

  return (
    <div className="min-h-screen p-4">
      <h1 className="text-2xl font-bold mb-4" style={{ color: theme.textColor }}>{t('explorePage.title')}</h1>
      
      <div className="mb-4 relative">
        <input
          type="text"
          placeholder={t('explorePage.searchPlaceholder')}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="w-full p-2 pl-10 rounded bg-gray-700 text-white"
        />
        <Search className="absolute left-3 top-2.5 w-5 h-5" style={{ color: theme.textColor }} />
      </div>

      <div className="mb-4 flex flex-wrap gap-2">
        {filters.map((filter) => (
          <button
            key={filter}
            onClick={() => setActiveFilter(filter)}
            className={`px-3 py-1 rounded-full text-sm ${activeFilter === filter ? 'bg-primary' : 'bg-gray-700'}`}
            style={{ color: theme.textColor, backgroundColor: activeFilter === filter ? theme.primaryColor : '' }}
          >
            {t(`explorePage.filters.${filter.toLowerCase()}`)}
          </button>
        ))}
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {selections.map((selection) => (
          <StartupsSelectionCard 
            key={selection.id} 
            name={selection.name}
            expert={selection.expert}
            startups={selection.startups}
            fundingGoal={selection.fundingGoal}
            currentFunding={selection.currentFunding}
            backers={selection.backers}
            remainingDays={selection.remainingDays}
            additionalFunding={selection.additionalFunding}
          />
        ))}
      </div>

      <Link to="/dashboard" className="mt-6 inline-block text-sm font-bold" style={{ color: theme.secondaryColor }}>
        {t('explorePage.backToDashboard')}
      </Link>
    </div>
  );
};

export default ExplorePage;
