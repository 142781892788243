import React, { useContext } from 'react';
import { DollarSign, TrendingUp, TrendingDown, PieChart, BarChart2 } from 'lucide-react';
import { ThemeContext } from '../ThemeContext';
import { useTranslation } from 'react-i18next';

const Card = ({ title, children }) => {
  const { theme } = useContext(ThemeContext);
  return (
    <div className="bg-gray-800 p-4 rounded-lg mb-4" style={{ backgroundColor: theme.cardBgColor }}>
      <h3 className="text-lg font-bold mb-2" style={{ color: theme.textColor }}>{title}</h3>
      {children}
    </div>
  );
};

const StatItem = ({ icon: Icon, label, value, trend }) => {
  const { theme } = useContext(ThemeContext);
  return (
    <div className="flex items-center justify-between mb-2">
      <div className="flex items-center">
        <Icon className="w-5 h-5 mr-2" style={{ color: theme.secondaryColor }} />
        <span className="text-sm" style={{ color: theme.textColor }}>{label}</span>
      </div>
      <div className="flex items-center">
        <span className="font-bold mr-2" style={{ color: theme.textColor }}>{value}</span>
        {trend && (
          <span style={{ color: trend > 0 ? '#10B981' : '#EF4444' }}>
            {trend > 0 ? <TrendingUp size={16} /> : <TrendingDown size={16} />}
            {Math.abs(trend)}%
          </span>
        )}
      </div>
    </div>
  );
};

const InvestmentItem = ({ name, amount, growth }) => {
  const { theme } = useContext(ThemeContext);
  const { t } = useTranslation();
  return (
    <div className="flex items-center justify-between mb-4">
      <div>
        <h4 className="font-bold" style={{ color: theme.textColor }}>{name}</h4>
        <p className="text-sm" style={{ color: theme.textColor }}>{t('investmentsPage.amountInvested', { amount })}</p>
      </div>
      <div className="text-right">
        <p className="font-bold" style={{ color: growth >= 0 ? '#10B981' : '#EF4444' }}>
          {growth >= 0 ? '+' : ''}{growth}%
        </p>
        <p className="text-sm" style={{ color: theme.textColor }}>{t('investmentsPage.growth')}</p>
      </div>
    </div>
  );
};

const InvestmentsPage = () => {
  const { theme } = useContext(ThemeContext);
  const { t } = useTranslation();

  // Mock data for investments
  const portfolioStats = {
    totalValue: 75000,
    invested: 50000,
    returns: 25000,
    overallGrowth: 32,
  };

  const investments = [
    { name: "AI Innovations", amount: 15000, growth: 45 },
    { name: "HealthTech Revolution", amount: 12000, growth: 28 },
    { name: "FinTech Disruptors", amount: 18000, growth: 38 },
    { name: "Green Energy Solutions", amount: 5000, growth: -5 },
  ];

  return (
    <div className="min-h-screen p-4">
      <h1 className="text-2xl font-bold mb-4" style={{ color: theme.textColor }}>{t('investmentsPage.title')}</h1>
      
      <Card title={t('investmentsPage.portfolioOverview')}>
        <StatItem icon={DollarSign} label={t('investmentsPage.totalValue')} value={`$${portfolioStats.totalValue}`} />
        <StatItem icon={PieChart} label={t('investmentsPage.invested')} value={`$${portfolioStats.invested}`} />
        <StatItem icon={TrendingUp} label={t('investmentsPage.returns')} value={`$${portfolioStats.returns}`} trend={portfolioStats.overallGrowth} />
      </Card>

      <Card title={t('investmentsPage.investmentDistribution')}>
        <BarChart2 className="w-full h-40 mb-2" style={{ color: theme.secondaryColor }} />
        <p className="text-sm text-center" style={{ color: theme.textColor }}>{t('investmentsPage.distributionDescription')}</p>
      </Card>

      <Card title={t('investmentsPage.currentInvestments')}>
        {investments.map((investment, index) => (
          <InvestmentItem key={index} {...investment} />
        ))}
      </Card>

      <button className="w-full py-2 rounded-full mt-4 font-bold" style={{ backgroundColor: theme.primaryColor, color: theme.textColor }}>
        {t('investmentsPage.exploreNewOpportunities')}
      </button>
    </div>
  );
};

export default InvestmentsPage;