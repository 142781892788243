import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { ThemeContext } from '../ThemeContext';
import { useTranslation } from 'react-i18next';

const ContributionPage = () => {
  const { theme } = useContext(ThemeContext);
  const [amount, setAmount] = useState('');
  const { t } = useTranslation();

  const selection = {
    name: "AI Innovations",
    expert: "Dr. Emily Chen",
    fundingGoal: 1000000,
    currentFunding: 750000,
    remainingDays: 15,
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Here you would typically handle the contribution submission
    console.log(`Contributing $${amount} to ${selection.name}`);
    // Redirect to payment processing page
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-4" style={{ color: theme.textColor }}>{t('contributionPage.contributeTo', { name: selection.name })}</h1>
      <p className="mb-8" style={{ color: theme.textColor }}>{t('contributionPage.curatedBy', { expert: selection.expert })}</p>

      <div className="mb-8 p-4 rounded-lg" style={{ backgroundColor: theme.cardBgColor }}>
        <h2 className="text-2xl font-semibold mb-4" style={{ color: theme.textColor }}>{t('contributionPage.fundingProgress')}</h2>
        <div className="mb-2 flex justify-between">
          <span style={{ color: theme.textColor }}>{t('contributionPage.raised', { amount: selection.currentFunding.toLocaleString() })}</span>
          <span style={{ color: theme.textColor }}>{t('contributionPage.goal', { amount: selection.fundingGoal.toLocaleString() })}</span>
        </div>
        <div className="w-full bg-gray-200 rounded-full h-2.5 mb-4">
          <div className="h-2.5 rounded-full" style={{ width: `${(selection.currentFunding / selection.fundingGoal) * 100}%`, backgroundColor: theme.primaryColor }}></div>
        </div>
        <div className="text-right">
          <span style={{ color: theme.textColor }}>{t('contributionPage.daysToGo', { days: selection.remainingDays })}</span>
        </div>
      </div>

      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label htmlFor="amount" className="block mb-2" style={{ color: theme.textColor }}>{t('contributionPage.contributionAmount')}</label>
          <input
            type="number"
            id="amount"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            className="w-full p-2 rounded border"
            style={{ backgroundColor: theme.cardBgColor, color: theme.textColor, borderColor: theme.primaryColor }}
            required
          />
        </div>
        <button
          type="submit"
          className="w-full py-2 rounded-full font-bold text-white"
          style={{ backgroundColor: theme.primaryColor }}
        >
          {t('contributionPage.contribute')}
        </button>
      </form>

      <div className="mt-8 text-center">
        <Link to="/selection-details" className="text-sm font-semibold" style={{ color: theme.secondaryColor }}>
          {t('contributionPage.backToSelectionDetails')}
        </Link>
      </div>
    </div>
  );
};

export default ContributionPage;