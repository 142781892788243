import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from '../ThemeContext';

const StartupsSelectionCard = ({
  name,
  expert,
  experts,
  startups,
  fundingGoal,
  currentFunding,
  backers,
  remainingDays,
  additionalFunding
}) => {
  const { theme } = useContext(ThemeContext);
  const { t } = useTranslation();

  const formatAmount = (amount) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(amount);
  };

  const fundingPercentage = (currentFunding / fundingGoal) * 100;

  // Handle both single expert and multiple experts
  const expertsArray = experts ? (Array.isArray(experts) ? experts : [experts]) : (expert ? [expert] : []);

  // Debug information
  console.log('Experts Array:', expertsArray);

  return (
    <div className="w-full rounded-lg overflow-hidden" style={{ backgroundColor: theme.cardBgColor, color: theme.textColor }}>
      <div className="p-4">
        {/* Header */}
        <div className="flex items-center space-x-2 mb-4">
          <div className="w-8 h-8 bg-purple-600 rounded-full flex items-center justify-center">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-white" viewBox="0 0 20 20" fill="currentColor">
              <path d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z" />
            </svg>
          </div>
          <h2 className="text-xl font-bold">{name}</h2>
        </div>

        {/* Selection Leads */}
        <div className="mb-4">
          <p className="text-green-500 text-xs mb-2">SELECTION LEAD</p>
          {expertsArray.map((expert, index) => (
            <div key={index} className="flex justify-between items-center mb-2">
              <div className="flex items-center space-x-3">
                <img src={`/images/${expert.imageUrl}`} alt={expert.name} className="w-12 h-12 rounded-full" />
                <div>
                  <p className="font-medium">{expert.name}</p>
                  <p className="text-sm text-gray-400">{expert.credentials}</p>
                </div>
              </div>
              <div className="text-sm font-medium text-right">
                <p className="text-gray-400">Lead Investment:</p>
                <p>+{formatAmount(expert.investment || 725000)}</p>
              </div>
            </div>
          ))}
        </div>

        {/* Startups */}
        <p className="text-green-500 text-xs mb-2">STARTUPS</p>
        <div className="grid grid-cols-3 gap-2 mb-4">
          {startups.map((startup) => (
            <div key={startup.id} className="bg-gray-800 rounded-lg p-2">
              <img src={startup.imageUrl} alt={startup.name} className="w-full h-16 object-cover rounded mb-2" />
              <p className="text-sm font-medium">{startup.name}</p>
              <p className="text-xs text-gray-400">{startup.description}</p>
            </div>
          ))}
        </div>

        {/* Funding Goal */}
        <div className="mb-4">
          <div className="flex justify-between items-center mb-2">
            <h3 className="text-lg font-semibold">
              Goal: {formatAmount(fundingGoal)}
            </h3>
            <span className="text-green-500">{formatAmount(currentFunding)}</span>
          </div>
          <div className="relative pt-1">
            <div className="overflow-hidden h-2 text-xs flex rounded bg-gray-700">
              <div
                style={{ width: `${fundingPercentage}%` }}
                className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-green-500"
              ></div>
            </div>
          </div>
          <div className="flex justify-between items-center text-sm mt-2">
            <span>{fundingPercentage.toFixed(0)}%</span>
            <div>
              <span>{t('startupsSelectionCard.daysLeft', { count: remainingDays })}</span>
              <span className="mx-2">•</span>
              <span>{t('startupsSelectionCard.backers', { count: backers })}</span>
            </div>
          </div>
        </div>

        {/* Contribute Button */}
        <button
          className="w-full py-2 rounded-full font-bold mb-4 bg-purple-600 text-white"
        >
          Contribute
        </button>

        {/* Additional Funding */}
        <p className="text-green-500 text-xs mb-2">ADDITIONAL FUNDING</p>
        <div className="space-y-2">
          {additionalFunding.map((funding, index) => (
            <div
              key={index}
              className="bg-gray-800 rounded-lg p-2 flex justify-between items-center"
            >
              <div>
                <p className="text-sm font-medium">{funding.source.name}</p>
                <p className="text-xs text-gray-400">{funding.type}</p>
              </div>
              <div className="bg-gray-700 px-2 py-1 rounded-full text-sm">
                {formatAmount(funding.amount)}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default StartupsSelectionCard;
