import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ThemeContext } from '../ThemeContext';
import { useTranslation } from 'react-i18next';

const PaymentProcessingPage = () => {
  const { theme } = useContext(ThemeContext);
  const navigate = useNavigate();
  const [progress, setProgress] = useState(0);
  const { t } = useTranslation();

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          clearInterval(timer);
          navigate('/confirmation');
          return 100;
        }
        const newProgress = oldProgress + 10;
        return Math.min(newProgress, 100);
      });
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, [navigate]);

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-8 text-center" style={{ color: theme.textColor }}>{t('paymentProcessingPage.title')}</h1>

      <div className="max-w-md mx-auto">
        <div className="mb-4 h-4 bg-gray-200 rounded-full">
          <div
            className="h-4 rounded-full transition-all duration-500 ease-out"
            style={{
              width: `${progress}%`,
              backgroundColor: theme.primaryColor
            }}
          ></div>
        </div>

        <p className="text-center mb-8" style={{ color: theme.textColor }}>
          {progress === 100 ? t('paymentProcessingPage.successMessage') : t('paymentProcessingPage.processingMessage')}
        </p>

        <div className="text-center">
          <svg className="inline-block animate-spin h-8 w-8 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>
        </div>
      </div>
    </div>
  );
};

export default PaymentProcessingPage;