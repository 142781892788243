import React, { useContext } from 'react';
import { ThemeContext } from '../ThemeContext';

const StartupProfilePage = () => {
  const { theme } = useContext(ThemeContext);

  const startup = {
    name: "NeuralTech",
    logo: "https://placekitten.com/200/200", // placeholder logo
    description: "NeuralTech is at the forefront of artificial intelligence, developing advanced neural network solutions for complex data analysis across various industries.",
    foundedYear: 2019,
    location: "San Francisco, CA",
    team: [
      { name: "Jane Doe", role: "CEO & Co-founder" },
      { name: "John Smith", role: "CTO & Co-founder" },
      { name: "Alice Johnson", role: "Head of Research" },
    ],
    metrics: [
      { label: "Annual Revenue", value: "$2.5M" },
      { label: "Employees", value: "25" },
      { label: "Customers", value: "50+" },
    ],
    fundingHistory: [
      { round: "Seed", amount: "$1.5M", date: "2019" },
      { round: "Series A", amount: "$5M", date: "2021" },
    ],
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex items-center mb-8">
        <img src={startup.logo} alt={startup.name} className="w-20 h-20 rounded-full mr-4" />
        <h1 className="text-3xl font-bold" style={{ color: theme.textColor }}>{startup.name}</h1>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        <div className="md:col-span-2">
          <h2 className="text-2xl font-semibold mb-4" style={{ color: theme.textColor }}>About</h2>
          <p className="mb-4" style={{ color: theme.textColor }}>{startup.description}</p>
          
          <h2 className="text-2xl font-semibold mb-4" style={{ color: theme.textColor }}>Team</h2>
          <ul className="mb-8">
            {startup.team.map((member, index) => (
              <li key={index} className="mb-2" style={{ color: theme.textColor }}>
                <span className="font-semibold">{member.name}</span> - {member.role}
              </li>
            ))}
          </ul>

          <h2 className="text-2xl font-semibold mb-4" style={{ color: theme.textColor }}>Funding History</h2>
          <ul className="mb-8">
            {startup.fundingHistory.map((round, index) => (
              <li key={index} className="mb-2" style={{ color: theme.textColor }}>
                <span className="font-semibold">{round.round}:</span> {round.amount} ({round.date})
              </li>
            ))}
          </ul>
        </div>

        <div>
          <div className="bg-white rounded-lg shadow p-6 mb-6" style={{ backgroundColor: theme.cardBgColor }}>
            <h2 className="text-xl font-semibold mb-4" style={{ color: theme.textColor }}>Quick Facts</h2>
            <p className="mb-2" style={{ color: theme.textColor }}><span className="font-semibold">Founded:</span> {startup.foundedYear}</p>
            <p className="mb-4" style={{ color: theme.textColor }}><span className="font-semibold">Location:</span> {startup.location}</p>
            <h3 className="font-semibold mb-2" style={{ color: theme.textColor }}>Key Metrics</h3>
            <ul>
              {startup.metrics.map((metric, index) => (
                <li key={index} className="mb-1" style={{ color: theme.textColor }}>
                  <span className="font-semibold">{metric.label}:</span> {metric.value}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StartupProfilePage;