import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import { app } from './firebase';

const functions = getFunctions(app);

// Check if the Functions Emulator is being used
const isUsingEmulator = !!process.env.REACT_APP_FIREBASE_FUNCTIONS_EMULATOR_HOST;

console.log('Functions.js - Environment Variables:');
console.log('REACT_APP_FIREBASE_FUNCTIONS_EMULATOR_HOST:', process.env.REACT_APP_FIREBASE_FUNCTIONS_EMULATOR_HOST);
console.log('isUsingEmulator:', isUsingEmulator);

if (isUsingEmulator) {
  const [host, port] = process.env.REACT_APP_FIREBASE_FUNCTIONS_EMULATOR_HOST.split(':');
  console.log(`Connecting to Functions emulator: ${host}:${port}`);
  connectFunctionsEmulator(functions, host, parseInt(port, 10));
  console.log('Connected to Functions emulator');
} else {
  console.log('Using production Firebase Functions');
}

export default functions;